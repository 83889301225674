@import "variables.scss";

.logo {
	position: absolute;
	z-index: 2;
	top: 12px;
	left: 12px;
}

.header-contacts {
	font-size: 13px;
	float: right;
	height: 60px;
	margin-top: 20px;
	p {
		line-height: 1.5;
		float: right;
		clear: both;
		width: auto;
		margin: 0;
		padding: 3px 0;
		img {
			float: left;
			margin-right: 5px;
		}
		&:last-child img {
			margin-top: 4px;
		}
		a {
			text-decoration: none;
			color: $color;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}


a.socials {
	position: absolute;
	display: block;
	width: 36px;
	height: 38px;
}

a.socials:nth-child(1) {
	top: 0;
	right: 72px;
	background: url('../images/socials.png') no-repeat 0 0;
}

a.socials:nth-child(2) {
	top: 0;
	right: 36px;
	background: url('../images/socials.png') no-repeat -36px 0;
}

a.socials:nth-child(3) {
	top: 0;
	right: 0;
	background: url('../images/socials.png') no-repeat -72px 0;
}

.mainMenu {
	display: block;
	overflow: hidden;
	width: 960px;
	height: 60px;
	margin: 0 auto 0 auto;
	padding-top: 8px;
	ul {
	margin-left: 200px;
		li {
			display: inline;
			list-style: none;
			a {
				font-size: 13px;
				line-height: 40px;
				display: block;
				float: left;
				width: 135px;
				text-align: center;
				text-decoration: none;
				color: $color;
				&:hover {
					color: #fff;
					background: #3649b0;
				}
			}
		}
	}
}

.shopLink {
	font-size: 13px;
	line-height: 40px;
	display: block;
	float: right;
	width: 150px;
	height: 40px;
	text-align: center;
	text-decoration: none;
	color: #fff;
	background: #da001f;

	&:hover {
		color: #fff;
		background: #670000;
	}
}

.region-highlighted {
	overflow: hidden;
	margin-bottom: 41px;
	img {
		margin: 0 35px 0 51px;
	}
	p {
		font-size: 16px;
		line-height: 1.6;
		margin: 0;
		padding: 0;
	}
}

.sidebar {
	position: absolute;
	top: 0;
	right: -110px;
	ul {
		margin: 0;
		padding: 0;
		li {
			display: block;
			clear: both;
			width: 218px;
			height: 53px;
			margin-right: -7px;
			margin-bottom: 10px;
			list-style: none;
			background: url('../images/sidebar.png') no-repeat;
			a {
				font-size: 13px;
				display: table-cell;
				width: 198px;
				height: 53px;
				padding-left: 20px;
				vertical-align: middle;
				text-decoration: none;
				color: #fff;
				&:hover {
					color: #8ECDF6
				}
				&:visited {
					color: #fff;
				}
			}
		}
	}
}

/* Таблица на главной */

.front .region-content {
	table {
		td {
			width: 270px;
			margin-right: 20px;

			p.rteright {
				margin-right: 37px;
			}
		}
	}
}

.region-content {
	li {
	line-height: 1.5;
	list-style: none;
		&:before {
			margin-right: 10px;
			content: url('../images/arrow.png');
		}
	}
}

/* Услуги */

.node-type-service {
	.group-photos {
			position: relative;
			float: left;
			overflow: visible;
			width: 550px;
			margin-bottom: 25px;
			padding:0 0 55px 0;
		.field-name-field-uslugi-big img {
			float: left;
			padding: 6px;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		}
		.field-name-field-uslugi-small .field-items {
			.field-item:nth-child(1) {
				position: absolute;
				bottom: 0;
				left: 23px;
			}
			.field-item:nth-child(2) {
				position: absolute;
				bottom: 0;
				left: 191px;
			}
			.field-item:nth-child(3) {
				position: absolute;
				bottom: 0;
				left: 359px;
			}
			img {
				padding: 6px;
				background: #fff;
				-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
				-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
				box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
				-webkit-transition: all .2s ease-in-out;
				-moz-transition: all .2s ease-in-out;
				-o-transition: all .2s ease-in-out;
				-ms-transition: all .2s ease-in-out;
				position: relative;

				&:hover {
					z-index: 999;
					-webkit-transform: scale(1.1); 
					-moz-transform: scale(1.1);
					-o-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
		}
	}
	.field-name-field-shortdescr {
		float: left;
		width: 240px;
		min-height: 485px;
		li {
			line-height: 1.5;
			list-style: none;
			&:before {
				margin-right: 10px;
				content: url('../images/arrow.png');
			}
		}
	}
	.field-name-body {
		clear: both;
		margin-bottom: 30px;
		p {
			margin: 0;
			padding: 0;
			line-height: 1.5;
		}
	}
	.field-name-field-uslugi-oth {
		overflow: visible;
		clear: both;
		width: 840px;
		img {
			float: left;
			margin: 0 10px 10px 0;
			padding: 6px;
			background: #fff;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			position: relative;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			-ms-transition: all .2s ease-in-out;
			&:hover {
				z-index: 999;
				-webkit-transform: scale(1.1); 
				-moz-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
			}
		}
		.field-item:nth-child(4n) img {
			margin-right: 0;
		}
	}
}

/* Новости */

.view-news {
	.views-row {
		overflow: hidden;
		width: 840px;
		margin-bottom: 20px;
		padding: 30px 70px 15px 70px;
		border-bottom: 6px solid #d7d7d7;
		background: #fff;
		&:last-child {
			margin-bottom: 0;
		}
		.views-field-title {
			font-size: 13px;
			font-weight: bold;
			padding-bottom: 23px;
			letter-spacing: 0.025em;
		}
		.views-field-body {
			float: left;
			width: 620px;
			p {
				line-height: 1.5;
			}
			.expanding-formatter-ellipsis {
				display: block;
				float: none;
				clear: both;
			}
			.expanding-formatter-trigger a {
				display: block;
				margin-top: 25px;
				text-decoration: none;
				color: #7b7b7b;
			}
		}
		.views-field-field-newsimage {
			float: right;
			padding: 6px;
			background: #fff;
			-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
			position: relative;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			-ms-transition: all .2s ease-in-out;
			&:hover {
				-webkit-transform: scale(1.1); 
				-moz-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
				z-index: 999;
			}
		}
		.views-field-field-newsdate {
			float: right;
			clear: both;
			margin-top: -20px;
			margin-right: 220px;
			.views-label,
			.field-content {
				float: left;
				margin-right: 5px;
				color: #7b7b7b;
			}
		}
	}
	.pager {
		margin-top: 30px;
		a {
			text-decoration: none;
			color: #d6d6d6;
		}
		.pager-current {
			color: #009BDE;
		}
	}
	div.expanding-formatter-trigger {
		display: block !important;
		clear: both;
		position: absolute;
		bottom: 0;
		margin-top: 20px;
	}

	.expanded .expanding-formatter-trigger {
		margin-top: 0;
	}
}

.expanding-formatter.expanding-formatter-processed {
	overflow: visible;
	padding-bottom: 40px;
}

/* Контакты */

#block-block-4 {
	overflow: hidden;
	width: 822px;
	height: 320px;
	margin-top: 30px;
	padding: 6px;
	background: #fff;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
	-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
	box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
}

.footer-block {
	font-size: 13px;
	float: left;
	width: 250px;
	min-height: 100px;
	margin-right: 20px;
	color: #fff;
	h2 {
		font-size: 13px;
		font-weight: normal;
		line-height: 1.5;
		margin: 0;
		padding: 0;
	}
	ul {
		margin:0 0 0 32px;
		padding: 0;
		li {
			list-style: none;
			margin: 0;
			padding: 0;
			a {
				font-size: 13px;
				line-height: 1.5;
				text-decoration: none;
				color: #fff;
				&:hover {
					color: #8ecdf6;
				}
			}
		}
	}
	&:last-child {
		ul li {
			margin-bottom: 25px;
		}
	}
}