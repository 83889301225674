@charset "UTF-8";
/*
* Third Party
*/
@import url(../../bower_components/normalize.css/normalize.css);
/*
* Custom
*/
body {
  font-family: Arial, 'Arial Regular', sans-serif;
  font-size: 12px;
  color: #404040;
  background: #fefefe url("../images/bg.jpg") center top; }

a {
  color: #009BDE; }
  a:hover {
    color: #060771; }

p {
  line-height: 1.5;
  letter-spacing: 0.025em; }

h1 {
  font-size: 13px;
  font-weight: bold;
  padding: 0; }

.page-node-10 h1 {
  float: right;
  padding-bottom: 10px;
  clear: both; }

.region-content {
  clear: both;
  font-weight: normal; }
  .region-content p {
    margin: 0; }

.l-header {
  width: 100%;
  height: 160px;
  background: url("../images/header.png") repeat-x; }
  .l-header .l-header-in {
    position: relative;
    clear: both;
    overflow: visible;
    width: 980px;
    height: 140px;
    margin: 0 auto; }

.l-socials {
  position: relative;
  width: 100%;
  min-width: 980px;
  max-width: 1200px;
  margin: 0 auto; }
  .l-socials .l-socials-in {
    z-index: 0;
    width: 980px;
    height: 50px;
    margin: 0 auto;
    background: #fff; }

.l-slider {
  width: 100%;
  min-width: 980px;
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  overflow: hidden; }
  .l-slider .views-field-field-sliderdesc {
    position: absolute;
    font-size: 18px;
    width: 100%;
    padding: 20px;
    background: #111;
    opacity: .7;
    text-align: center;
    color: #fff;
    bottom: 0; }

.l-content {
  overflow: visible;
  position: relative;
  width: 850px;
  margin: 0 auto;
  padding: 35px 65px;
  border-bottom: 6px #d6d6d6;
  background: #fff; }
  .l-content a:visited {
    color: #060771; }
  .l-content .field-name-body img {
    padding: 6px;
    background: #fff;
    position: relative;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out; }
    .l-content .field-name-body img:hover {
      z-index: 999;
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }

.l-contentWSidebar {
  width: 790px;
  padding: 35px 120px 35px 70px; }

.page-news .l-content {
  background: none;
  padding: 0 0 35px 0;
  width: 980px; }

.l-afterContent {
  overflow: hidden;
  width: 840px;
  margin: 20px auto 0 auto;
  background: #fff;
  padding: 30px 70px 35px 70px; }
  .l-afterContent img {
    padding: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    margin-right: 20px; }

.l-footer {
  width: 850px;
  font-size: 13px;
  margin: 0 auto;
  padding: 30px 65px;
  color: #fff;
  border-bottom: 4px solid #d6d6d6;
  overflow: hidden;
  clear: both; }

.l-copyright {
  width: 980px;
  margin: 0 auto; }
  .l-copyright p {
    padding: 30px 0;
    margin: 0;
    color: #fff;
    font-size: 12px;
    float: left; }
    .l-copyright p.nendo {
      float: right; }

.logo {
  position: absolute;
  z-index: 2;
  top: 12px;
  left: 12px; }

.header-contacts {
  font-size: 13px;
  float: right;
  height: 60px;
  margin-top: 20px; }
  .header-contacts p {
    line-height: 1.5;
    float: right;
    clear: both;
    width: auto;
    margin: 0;
    padding: 3px 0; }
    .header-contacts p img {
      float: left;
      margin-right: 5px; }
    .header-contacts p:last-child img {
      margin-top: 4px; }
    .header-contacts p a {
      text-decoration: none;
      color: #404040; }
      .header-contacts p a:hover {
        text-decoration: underline; }

a.socials {
  position: absolute;
  display: block;
  width: 36px;
  height: 38px; }

a.socials:nth-child(1) {
  top: 0;
  right: 72px;
  background: url("../images/socials.png") no-repeat 0 0; }

a.socials:nth-child(2) {
  top: 0;
  right: 36px;
  background: url("../images/socials.png") no-repeat -36px 0; }

a.socials:nth-child(3) {
  top: 0;
  right: 0;
  background: url("../images/socials.png") no-repeat -72px 0; }

.mainMenu {
  display: block;
  overflow: hidden;
  width: 960px;
  height: 60px;
  margin: 0 auto 0 auto;
  padding-top: 8px; }
  .mainMenu ul {
    margin-left: 200px; }
    .mainMenu ul li {
      display: inline;
      list-style: none; }
      .mainMenu ul li a {
        font-size: 13px;
        line-height: 40px;
        display: block;
        float: left;
        width: 135px;
        text-align: center;
        text-decoration: none;
        color: #404040; }
        .mainMenu ul li a:hover {
          color: #fff;
          background: #3649b0; }

.shopLink {
  font-size: 13px;
  line-height: 40px;
  display: block;
  float: right;
  width: 150px;
  height: 40px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background: #da001f; }
  .shopLink:hover {
    color: #fff;
    background: #670000; }

.region-highlighted {
  overflow: hidden;
  margin-bottom: 41px; }
  .region-highlighted img {
    margin: 0 35px 0 51px; }
  .region-highlighted p {
    font-size: 16px;
    line-height: 1.6;
    margin: 0;
    padding: 0; }

.sidebar {
  position: absolute;
  top: 0;
  right: -110px; }
  .sidebar ul {
    margin: 0;
    padding: 0; }
    .sidebar ul li {
      display: block;
      clear: both;
      width: 218px;
      height: 53px;
      margin-right: -7px;
      margin-bottom: 10px;
      list-style: none;
      background: url("../images/sidebar.png") no-repeat; }
      .sidebar ul li a {
        font-size: 13px;
        display: table-cell;
        width: 198px;
        height: 53px;
        padding-left: 20px;
        vertical-align: middle;
        text-decoration: none;
        color: #fff; }
        .sidebar ul li a:hover {
          color: #8ECDF6; }
        .sidebar ul li a:visited {
          color: #fff; }

/* Таблица на главной */
.front .region-content table td {
  width: 270px;
  margin-right: 20px; }
  .front .region-content table td p.rteright {
    margin-right: 37px; }

.region-content li {
  line-height: 1.5;
  list-style: none; }
  .region-content li:before {
    margin-right: 10px;
    content: url("../images/arrow.png"); }

/* Услуги */
.node-type-service .group-photos {
  position: relative;
  float: left;
  overflow: visible;
  width: 550px;
  margin-bottom: 25px;
  padding: 0 0 55px 0; }
  .node-type-service .group-photos .field-name-field-uslugi-big img {
    float: left;
    padding: 6px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2); }
  .node-type-service .group-photos .field-name-field-uslugi-small .field-items .field-item:nth-child(1) {
    position: absolute;
    bottom: 0;
    left: 23px; }
  .node-type-service .group-photos .field-name-field-uslugi-small .field-items .field-item:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 191px; }
  .node-type-service .group-photos .field-name-field-uslugi-small .field-items .field-item:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 359px; }
  .node-type-service .group-photos .field-name-field-uslugi-small .field-items img {
    padding: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    position: relative; }
    .node-type-service .group-photos .field-name-field-uslugi-small .field-items img:hover {
      z-index: 999;
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }

.node-type-service .field-name-field-shortdescr {
  float: left;
  width: 240px;
  min-height: 485px; }
  .node-type-service .field-name-field-shortdescr li {
    line-height: 1.5;
    list-style: none; }
    .node-type-service .field-name-field-shortdescr li:before {
      margin-right: 10px;
      content: url("../images/arrow.png"); }

.node-type-service .field-name-body {
  clear: both;
  margin-bottom: 30px; }
  .node-type-service .field-name-body p {
    margin: 0;
    padding: 0;
    line-height: 1.5; }

.node-type-service .field-name-field-uslugi-oth {
  overflow: visible;
  clear: both;
  width: 840px; }
  .node-type-service .field-name-field-uslugi-oth img {
    float: left;
    margin: 0 10px 10px 0;
    padding: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    position: relative;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out; }
    .node-type-service .field-name-field-uslugi-oth img:hover {
      z-index: 999;
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }
  .node-type-service .field-name-field-uslugi-oth .field-item:nth-child(4n) img {
    margin-right: 0; }

/* Новости */
.view-news .views-row {
  overflow: hidden;
  width: 840px;
  margin-bottom: 20px;
  padding: 30px 70px 15px 70px;
  border-bottom: 6px solid #d7d7d7;
  background: #fff; }
  .view-news .views-row:last-child {
    margin-bottom: 0; }
  .view-news .views-row .views-field-title {
    font-size: 13px;
    font-weight: bold;
    padding-bottom: 23px;
    letter-spacing: 0.025em; }
  .view-news .views-row .views-field-body {
    float: left;
    width: 620px; }
    .view-news .views-row .views-field-body p {
      line-height: 1.5; }
    .view-news .views-row .views-field-body .expanding-formatter-ellipsis {
      display: block;
      float: none;
      clear: both; }
    .view-news .views-row .views-field-body .expanding-formatter-trigger a {
      display: block;
      margin-top: 25px;
      text-decoration: none;
      color: #7b7b7b; }
  .view-news .views-row .views-field-field-newsimage {
    float: right;
    padding: 6px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
    position: relative;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out; }
    .view-news .views-row .views-field-field-newsimage:hover {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1);
      z-index: 999; }
  .view-news .views-row .views-field-field-newsdate {
    float: right;
    clear: both;
    margin-top: -20px;
    margin-right: 220px; }
    .view-news .views-row .views-field-field-newsdate .views-label,
    .view-news .views-row .views-field-field-newsdate .field-content {
      float: left;
      margin-right: 5px;
      color: #7b7b7b; }

.view-news .pager {
  margin-top: 30px; }
  .view-news .pager a {
    text-decoration: none;
    color: #d6d6d6; }
  .view-news .pager .pager-current {
    color: #009BDE; }

.view-news div.expanding-formatter-trigger {
  display: block !important;
  clear: both;
  position: absolute;
  bottom: 0;
  margin-top: 20px; }

.view-news .expanded .expanding-formatter-trigger {
  margin-top: 0; }

.expanding-formatter.expanding-formatter-processed {
  overflow: visible;
  padding-bottom: 40px; }

/* Контакты */
#block-block-4 {
  overflow: hidden;
  width: 822px;
  height: 320px;
  margin-top: 30px;
  padding: 6px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2); }

.footer-block {
  font-size: 13px;
  float: left;
  width: 250px;
  min-height: 100px;
  margin-right: 20px;
  color: #fff; }
  .footer-block h2 {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
    padding: 0; }
  .footer-block ul {
    margin: 0 0 0 32px;
    padding: 0; }
    .footer-block ul li {
      list-style: none;
      margin: 0;
      padding: 0; }
      .footer-block ul li a {
        font-size: 13px;
        line-height: 1.5;
        text-decoration: none;
        color: #fff; }
        .footer-block ul li a:hover {
          color: #8ecdf6; }
  .footer-block:last-child ul li {
    margin-bottom: 25px; }

img.is-zindex {
  z-index: 999;
  position: relative; }
