@import "variables.scss";

.l-header {
	width: 100%;
	height: 160px;
	background: url('../images/header.png') repeat-x;
	.l-header-in {
		position: relative;
		clear: both;
		overflow: visible;
		width: 980px;
		height: 140px;
		margin: 0 auto;
	}
}

.l-socials {
	position: relative;
	width: 100%;
	min-width: 980px;
	max-width: 1200px;
	margin: 0 auto;
	.l-socials-in {
		z-index: 0;
		width: 980px;
		height: 50px;
		margin: 0 auto;
		background: #fff;
	}
}

.l-slider {
	width: 100%;
	min-width: 980px;
	max-width: 1200px;
	height: auto;
	margin: 0 auto;
	overflow: hidden;

	.views-field-field-sliderdesc {
		position: absolute;
		font-size: 18px;
		width: 100%;
		padding: 20px;
		background: #111;
		opacity: .7;
		text-align: center;
		color: #fff;
		bottom: 0;
	}
}

.l-content {
	overflow: visible;
	position: relative;
	width: 850px;
	margin: 0 auto;
	padding: 35px 65px;
	border-bottom: 6px #d6d6d6;
	background: #fff;
	a:visited {
		color: #060771;
	}

	.field-name-body img {
		padding: 6px;
		background: #fff;
		position: relative;
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		-webkit-transition: all .2s ease-in-out;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		-ms-transition: all .2s ease-in-out;

		&:hover {
			z-index: 999;
			-webkit-transform: scale(1.1); 
			-moz-transform: scale(1.1);
			-o-transform: scale(1.1);
			transform: scale(1.1);
		}
	}

	.content img {

	}
}

.l-contentWSidebar {
	width: 790px;
	padding: 35px 120px 35px 70px;
}

.page-news .l-content {
	background: none;
	padding: 0 0 35px 0;
	width: 980px;
}

.l-afterContent {
	overflow: hidden;
	width: 840px;
	margin: 20px auto 0 auto;
	background: #fff;
	padding: 30px 70px 35px 70px;

	img {
		padding: 6px;
		background: #fff;
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		-moz-box-shadow:    0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		box-shadow:         0px 0px 5px 0px rgba(0, 0, 4, 0.2);
		margin-right: 20px;
	}
}

.l-footer {
	width: 850px;
	font-size: 13px;
	margin: 0 auto;
	padding: 30px 65px;
	color: #fff;
	border-bottom: 4px solid #d6d6d6;
	overflow: hidden;
	clear: both;
}

.l-copyright {
	width: 980px;
	margin: 0 auto;
	p {
		padding: 30px 0;
		margin: 0;
		color: #fff;
		font-size: 12px;
		float: left;

		&.nendo {
			float: right;
		}
	}
}