/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";

/*
* Custom
*/
@import "variables.scss";

body {
	font-family: Arial, 'Arial Regular', sans-serif;
	font-size: 12px;
	color: $color;
	background: $bg url('../images/bg.jpg') center top;
}

a {
	color: #009BDE;

	&:hover {
		color: #060771;
	}
}

p {
	line-height: 1.5;
	letter-spacing: 0.025em;
}

h1 {
	font-size: 13px;
	font-weight: bold;
	padding: 0;
}

.page-node-10 h1 {
	float: right;
	padding-bottom: 10px;
	clear: both;
}

.region-content {
	clear: both;
	font-weight: normal;

	p {
		margin: 0;
	}
}

@import "layot.scss";
@import "module.scss";
@import "state.scss";

@import "media.scss";